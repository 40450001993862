.noprint {
  @media print {
    display: none !important;
  }
}

@page {
  margin-top: 1cm !important;
  margin-bottom: 1cm !important;
  margin-left: 0.5cm !important;
  margin-right: 0.5cm !important;
}

.avoidPageBreak {
  page-break-inside: avoid;
}
